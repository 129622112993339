import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import picicon from '../images/iconinfo.png'

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      window.history.back()
    }, 3000)
  }

  render() {
    return (
      <div className="infoPage">
        <Layout>
          <h2><Link to="/">Le message a bien été envoyé</Link></h2>
          <p>Merci pour votre message.</p>
          <p>
            <span className="image infoImage">
              <Link to="/"><img src={picicon} alt="Back" /></Link>
            </span>
          </p>
        </Layout>
      </div>
    );
  }
}

export default SuccessPage
